import React from 'react';
import { Button, Form, Input } from 'antd';
import { useCreatePixelMutation } from 'modules/facebook-pixel/api/pixels.api';
import { snackbar } from 'components/notifications/notifications';
import PropTypes from 'prop-types';

const CreateFacebookPixelForm = (props) => {
  const [form] = Form.useForm();
  const [createPixelQuery, { isLoading }] = useCreatePixelMutation();
  const formInitialValues = {
    name: "MyDataninja's Pixel",
    account_id: props.accountId,
  };

  const onSuccess = (data) => {
    form.resetFields();
    props.onSuccess?.(data);
  };

  const createPixel = (values) => {
    createPixelQuery(values)
      .unwrap()
      .then((data) => {
        snackbar.success('Pixel created successfully');
        onSuccess(data);
      })
      .catch(() => {
        snackbar.error('Failed to create pixel');
      });
  };

  return (
    <Form
      form={form}
      disabled={isLoading}
      name='layout-multiple-horizontal'
      layout='vertical'
      initialValues={formInitialValues}
      onFinish={createPixel}
      autoComplete='off'
    >
      <Form.Item hidden name='account_id' />
      <Form.Item layout='vertical' label='Pixel Name' name='name' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item className='flex-center pt-4'>
        <Button loading={isLoading} type='primary' htmlType='submit'>
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};

CreateFacebookPixelForm.propTypes = {
  accountId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
};

export default CreateFacebookPixelForm;
