import '@ninja';
import rootRoutes from 'router/rootRoutes';
import 'react-data-grid/lib/styles.css';
import { renderRoutes } from 'router/renderRoutes';
import storage from 'utils/storage/advanced-storage';
import useBootstrapUser from 'store/redux/hooks/actions/useBootstrapUser';
// deployment check

const App = () => {
  useBootstrapUser();

  return renderRoutes(rootRoutes);
};

export default App;
