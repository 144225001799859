import { List } from '@material-ui/core';
import {
  mdiAnchor,
  mdiBookAccount,
  mdiAccountBoxMultiple,
  mdiBullhorn,
  mdiChartArc,
  mdiChartPie,
  mdiCrystalBall,
  mdiDesktopMacDashboard,
  mdiRelationOneToMany,
  mdiRulerSquareCompass,
  mdiSpeedometer,
  mdiTelegram,
  mdiViewDashboard,
  mdiLink,
  mdiNotebookEditOutline,
  mdiWeb,
  mdiApps,
} from '@mdi/js';
import Icon from '@mdi/react';
import SingleMenu from 'components/layouts/components/Sidebar/SingleMenu';
import React from 'react';
import P from 'router/paths';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { useSidebar } from 'store/redux/states/ui/ui.hooks';
import { usePermissions } from 'store/redux/apis/workspace/permissions.api';

const SidebarMenu = () => {
  const [sidebarOpen] = useSidebar();
  const { workspace } = useWorkspace();
  const { canView, canEdit } = usePermissions();
  return (
    <List className='flex-1 pt-4 relative border border-[#2f3641] overflow-x-hidden'>
      <SingleMenu
        url={P.DASHBOARD.INDEX}
        sidebarOpen={sidebarOpen}
        text='Dashboard'
        icon={<Icon path={mdiViewDashboard} />}
      />
      <SingleMenu
        disabled={!canView('sale')}
        url={P.SALES.INDEX}
        sidebarOpen={sidebarOpen}
        text='Sales'
        icon={<Icon path={mdiSpeedometer} />}
      />
      <SingleMenu
        disabled={!canView('conversion')}
        url={P.CONVERSIONS.INDEX}
        sidebarOpen={sidebarOpen}
        text='Conversions'
        icon={<Icon path={mdiDesktopMacDashboard} />}
      />
      <SingleMenu
        disabled={!canView('conversionrule')}
        url={P.CONVERSION_RULES.INDEX}
        sidebarOpen={sidebarOpen}
        text='Conversion Rules'
        icon={<Icon path={mdiNotebookEditOutline} />}
      />
      <SingleMenu
        id='reporting'
        disabled={!canView('reporting')}
        url={P.REPORTING.TABLE.replace(':tab', 'adset')}
        sidebarOpen={sidebarOpen}
        text='Reporting'
        icon={<Icon path={mdiChartArc} />}
      />
      <SingleMenu
        disabled={!canView('crm')}
        url={
          workspace.crm_form_id
            ? P.CRM.DATA_LIST.replace(':id', workspace.crm_form_id)
            : P.CRM.INDEX
        }
        sidebarOpen={sidebarOpen}
        text='CRM'
        icon={<Icon path={mdiBookAccount} />}
      />
      <SingleMenu
        disabled={!canView('website')}
        url={P.DOMAIN.INDEX}
        sidebarOpen={sidebarOpen}
        text='Websites'
        icon={<Icon path={mdiWeb} />}
      />
      <SingleMenu
        disabled={!canView('postbacks')}
        url={P.POSTBACKS.INDEX}
        sidebarOpen={sidebarOpen}
        text='Postbacks'
        icon={<Icon path={mdiAnchor} />}
      />
      <SingleMenu
        disabled={!canView('ninjaurl')}
        url={P.URL.INDEX}
        sidebarOpen={sidebarOpen}
        text='Custom Urls'
        icon={<Icon path={mdiLink} />}
      />
      <SingleMenu
        disabled={!canView('networkaccount')}
        url={P.ACCOUNTS.INDEX}
        sidebarOpen={sidebarOpen}
        text='Accounts'
        icon={<Icon path={mdiAccountBoxMultiple} />}
      />
      <SingleMenu
        url={P.APPS.INDEX}
        sidebarOpen={sidebarOpen}
        text='Apps'
        icon={<Icon path={mdiApps} />}
      />
      <SingleMenu
        disabled={workspace?.id !== 114}
        url={P.AD.CREATE}
        sidebarOpen={sidebarOpen}
        text='Ad Creation'
        icon={<Icon path={mdiBullhorn} />}
      />
      <SingleMenu
        disabled={true}
        url='#'
        sidebarOpen={sidebarOpen}
        text='Website Builder'
        icon={<Icon path={mdiCrystalBall} />}
      />
      <SingleMenu
        disabled={true}
        url={P.AUDIENCES.INDEX}
        sidebarOpen={sidebarOpen}
        text='Audiences'
        icon={<Icon path={mdiChartPie} />}
      />
      <SingleMenu
        disabled={true}
        url='#'
        sidebarOpen={sidebarOpen}
        text='Manual Sender'
        icon={<Icon path={mdiTelegram} />}
      />
      <SingleMenu
        disabled={true}
        url='#'
        sidebarOpen={sidebarOpen}
        text='Flow Builder'
        icon={<Icon path={mdiRelationOneToMany} />}
      />
    </List>
  );
};

export default SidebarMenu;
