import React, { useEffect, useMemo, useState } from 'react';
import NetworkIcon from 'components/NetworkIcon';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Menu } from 'antd';
import useQueryParams from 'hooks/useQueryParams';
import useFacebookLogin from 'modules/network-accounts/hooks/useFacebookLogin';
import DocumentationButton from 'components/buttons/DocumentationButton';
import useGoogleLogin from 'modules/network-accounts/hooks/useGoogleLogin';

const items = [
  {
    label: 'Facebook',
    key: 'select_facebook',
    icon: <NetworkIcon network='facebook' />,
  },
  {
    label: 'Google',
    key: 'select_google',
    icon: <NetworkIcon network='google' />,
  },
  {
    label: 'Connect',
    icon: <Icon path={mdiPlus} />,
    children: [
      { icon: <NetworkIcon network='facebook' />, label: 'Facebook', key: 'connect_facebok' },
      { icon: <NetworkIcon network='google' />, label: 'Google', key: 'connect_google' },
    ],
  },
];

const NetworkAccountMainMenu = () => {
  const [selected, setSelected] = useState([]);
  const {
    loading: facebookLoading,
    loadSDK: loadFacebookSDK,
    openLogin: openFacebookLogin,
  } = useFacebookLogin();
  const {
    loading: googleLoading,
    loadSDK: loadGoogleSDK,
    openLogin: openGoogleLogin,
  } = useGoogleLogin();
  const { setUrlParams, searchParams } = useQueryParams();

  const actions = useMemo(
    () => ({
      select_facebook: () => {
        setUrlParams({ network: 'facebook' });
      },
      select_google: () => {
        setUrlParams({ network: 'google' });
      },
      connect_facebok: () => {
        loadFacebookSDK()
          .then(() => openFacebookLogin())
          .then(actions.select_facebook);
      },
      connect_google: () => {
        loadGoogleSDK()
          .then(() => openGoogleLogin())
          .then(actions.select_google);
      },
    }),
    []
  );

  const onSelect = ({ key }) => {
    if (actions[key]) {
      actions[key]();
    }
  };

  useEffect(() => {
    const { network } = searchParams;
    setSelected((s) => [`select_${network}`]);
  }, [searchParams]);

  return (
    <div className='flex mt-1 items-center px-4 pr-8'>
      <Menu
        className='px-0 flex-grow'
        mode='horizontal'
        selectedKeys={selected}
        onSelect={onSelect}
        items={items}
      />
      <DocumentationButton
        type='primary'
        tooltip='View docs'
        link='https://mydataninja.com/connect-ad-accounts-mydataninja/'
      />
    </div>
  );
};

export default NetworkAccountMainMenu;
