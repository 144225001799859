import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import P from 'router/paths';
import {
  useAcceptInviteMutation,
  useLazyGetInvitationByTokenQuery,
} from 'store/redux/apis/workspace/permissions.api';
import url from '@ninja/url';
import { Typography } from 'antd';
import UrlHelper from 'common/utils/url-helper';

const ConfirmInvitationPage = () => {
  const invite_token = url.get('invite_token');
  const history = useHistory();
  const [getInvitation, { data: invitation, isLoading: isInvitationLoading }] =
    useLazyGetInvitationByTokenQuery();
  const [acceptInvite, { isLoading: isAcceptLoading }] = useAcceptInviteMutation();

  const acceptInvitation = () => {
    acceptInvite({
      hash: invite_token,
    })
      .unwrap()
      .then((response) => {
        redirectToDashboard();
      })
      .catch((err) => {
        if (err.status == 401) {
          history.push(
            P.AUTH.REGISTER + `?email=${btoa(invitation.email)}&invite_token=${invite_token}`
          );
        }
      });
  };

  useEffect(() => {
    getInvitation({
      hash: invite_token,
    })
      .unwrap()
      .then((d) => {
        console.log(d);
      });
  }, [invite_token]);

  const redirectToLogin = () => {
    history.push(P.AUTH.LOGIN);
  };

  const redirectToDashboard = () => {
    history.push(P.DASHBOARD.INDEX);
  };

  return (
    <div className='w-full h-full p-4 md:p-8 overflow-auto flex flex-center min-h-[100%] bg-background-dark '>
      <div className='flex flex-col w-full h-full mx-auto items-center'>
        <a className='flex no-underline flex-col' href={UrlHelper.home()}>
          <img className='m-auto w-[150px]' src='/icons/dataninja/dataninja.svg' />
          <div className='relative'>
            <span className='absolute text-white tracking-wider -right-6 bottom-16 text-xs bg-orange font-medium rounded-3xl px-1 py-0.5'>
              BETA
            </span>
            <Typography.Title level={1} className='!text-white'>
              MyDataNinja
            </Typography.Title>
          </div>
        </a>

        <div className='bg-white p-5 rounded-md shadow-md transition-all duration-300 w-full md:w-1/3 lg:w-1/5'>
          <form className='disable-antd-margin'>
            <div className='grid gap-6'>
              <div className='text-center'>
                <p className='text-base font-normal'>You've been invited to join</p>
                <h2 className='text-2xl text-orange font-bold'>{invitation?.workspace_name}</h2>
              </div>
            </div>
            <div className='flex-center flex-col'>
              <div className='flex-center pt-4'>
                <Button
                  size='medium'
                  loading={isInvitationLoading || isAcceptLoading}
                  onClick={acceptInvitation}
                  color='secondary'
                >
                  Accept
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ConfirmInvitationPage;
